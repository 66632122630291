export default [
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required|max:500",
    type: "textarea",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "max:500",
    type: "textarea",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
